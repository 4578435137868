<template>
    <div class="_cockpit">
        <div class="_cockpit_tit">
            <div class="_cockpit_tit_left">
                <span>{{currentdate}}</span>
                <span>{{currentweek}}</span>
                <!-- <span>晴</span> -->
            </div>
           <p class="c_title"> 训练基地智慧驾驶舱</p>
           <div class="_cockpit_tit_right">
               <p>适配《运动员科学化训练管理系统》</p>
               <div class="_cockpit_select">
                   <!-- <el-select v-model="f_value" placeholder="请选择">
                        <el-option
                            v-for="item in f_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select> -->

                    <el-cascader
                        @change="getData"
                        style="float: left;"
                        placeholder="请选择运动队"
                        v-model="deparmentId"
                        :options="departmentOptions"
                        collapse-tags
                        :show-all-levels="false"
                        :props="{multiple: true, children: 'children', label: 'name', value: 'uuid', emitPath: false}"></el-cascader>

                    <span :class="weekType ? 'titleActive title_week title_btn' : 'title_week title_btn'" @click="weekClick('week')">本周</span>
                    <span :class="weekType ? 'title_month title_btn' : 'titleActive title_month title_btn'" @click="weekClick('month')">本月</span>
               </div>
                
           </div>
        </div>
        <!-- <div class="ranks">
            1234
        </div> -->
       <div class="_cockpit_box">
            <div class="_cockpit_lft">
                <!-- 人员概览 -->
                <div class="_personnel">
                   <p class="_modularTit">人员概览</p>
                    <ul class="person_ul">
                        <li>
                            <span>{{personData.staff_num}}</span>
                           <p>总人数(人)</p> 
                        </li>
                        <li>
                           <span>{{personData.department_num}}</span>
                           <p>运动队数(个)</p> 
                        </li>
                        <li>
                           <span>{{personData.staffin_num}}</span>
                           <p>在队人数(人)</p> 
                        </li>
                        <li>
                           <span>{{personData.staff_outnum}}</span>
                           <p>离队人数(人)</p> 
                        </li>
                    </ul>
                </div>
                <!-- 各运动队 -->
                <div class="_team">
                    <p class="_modularTit">各运动队在队人数统计(人)</p>
                    <ul class="_team_ul">
                        <li v-for="(d_item, d_index) in departmentData" :key="d_item.uuid"> 
                            <i class="_team_ul_number">{{d_index + 1}}</i><span class="_team_ul_name">{{d_item.name}}</span>
                            <el-progress :percentage="d_item.staff_num" :format="progressFormat"></el-progress>
                        </li>
                    </ul>
                </div>
                <!-- 年龄统计 -->
                <div class="_ageSata">
                   <p class="_modularTit">在队运动员年龄统计</p>
                   <div class="_ageSata_echarts" id="_ageSata_echarts"></div>
                </div>
                <!--性别统计 -->
                <div class="_sexSata">
                   <p class="_modularTit">在队运动员性别统计</p>
                   <ul class="_sexSata_img">
                        <li v-for="man in (sexSataType ? 5 : 4)" :key="man + 'm'"><img src="./../../static/man_t.png" alt=""></li>
                        <li v-for="woman in (sexSataType ? 4 : 5)" :key="woman + 'w'"><img src="./../../static/woman_t.png" alt=""></li>
                   </ul>
                   <div class="_sexSata_number">
                       <span class="man_number" :style="{'width': sexSataType ? '55%' : '45%'}">{{sexStaffData[1] && sexStaffData[1].num}}人</span>
                       <span class="woman_number" :style="{'width': sexSataType ? '45%' : '55%'}">{{sexStaffData[2] && sexStaffData[2].num}}人</span>
                   </div>
                </div>
            </div>
            <div class="_cockpit_cont">
                <!-- 今日数据 -->
                <div class="_today">
                    <p class="_modularTit">今日数据记录人数</p>
                    <div class="_today_bot_back">
                        <div class="_today_conImg">
                            <span>
                                <b>{{recordStatisticsData.train_num}}</b></br>（人）
                            </span>
                            <p>训练计划建立人数</p>
                        </div>
                        <div class="_today_conImg">
                            <span>
                                <b>{{recordStatisticsData.ydbx}}</b></br>（人）
                            </span>
                            <p>运动表现记录人数</p>
                        </div>
                        <div class="_today_conImg">
                            <span>
                                <b>{{recordStatisticsData.zgzt}}</b></br>（人）
                            </span>
                            <p>主观状态记录人数</p>
                        </div>
                        <div class="_today_conImg">
                            <span>
                                <b>{{recordStatisticsData.injury}}</b></br>（人）
                            </span>
                            <p>伤病人数</p>
                        </div>
                        <div class="_today_conImg">
                            <span>
                                <b>{{recordStatisticsData.tncs}}</b></br>（人）
                            </span>
                            <p>体能测试人数</p>
                        </div>
                        <div class="_today_back">
                            <vue-particles
                                color="#fff"
                                :particleOpacity="0.1"
                                :particlesNumber="40"
                                shapeType="circle"
                                :particleSize="30"
                                linesColor="#fff"
                                :linesWidth="1"
                                :lineLinked="false"
                                :lineOpacity="0.4"
                                :linesDistance="200"
                                :moveSpeed="5"
                                :hoverEffect="false"
                                hoverMode="repulse"
                                :clickEffect="false"
                                clickMode="push"
                                class="lizi"
                                :style="'height: 100%;'"
                            >
                            </vue-particles>
                        </div>
                    </div>
                </div>
                <!-- 近一年数据 -->
                <div class="_year">
                    <p class="_modularTit">近一年测试记录次数总览</p>
                    <div class="_year_content" id="_year_content">
                    </div>
                </div>
            </div>
            <div class="_cockpit_rig">
                <div class="_traSite">
                    <p class="_modularTit">训练部位频次统计</p>
                    <div class="_traSite_echarts" id="_traSite_echarts"></div>
                </div>
                <div class="_funJK">
                    <p class="_modularTit">机能指标测试频次统计</p>
                    <div class="_funJK_echarts" id="_funJK_echarts"></div>
                </div>
                <div class="_Injury">
                    <p class="_modularTit">伤病情况人数统计（人）</p>
                    <div class="_Injury_progress">
                        <h2>伤病部位前5名人数</h2>
                        <ul class="_Injury_ul">
                            <li v-for="(item, r_index) in rightStatisticsData.injury_position" :key="r_index + 'a'"> 
                                <span class="_team_ul_name">{{item.name}}</span>
                                <el-progress :percentage="item.num" :format="progressFormat"></el-progress>
                            </li>
                        </ul>
                    </div>
                    <div class="_Injury_echarts_content">
                        <h2>伤病类型人数占比</h2>
                        <div class="_Injury_echarts" id="_Injury_echarts">

                        </div>
                    </div>
                </div>
            </div>
       </div>
    </div>
    
</template>


<script>

export default {
    name: 'WorkspaceJsonSmartcockpit',

    data() {
        return {
            percentage: 0,
            sexSataType: false,
            f_options: [],
            f_value: '',
            currentdate: '',
            currentweek: '',
            deparmentId: [],
            departmentOptions: [],
            weekType: true,
            startTime: '',
            endTime: '',
            injury_total: 0,


            personData: {}, // 人员总览
            departmentData: [], // 在队人数
            ageStaffData: [],  // 年龄统计
            sexStaffData: [], // 性别统计
            recordStatisticsData: [], // 今日统计
            yearStatisticsData: [], // 近一年测试记录次数总览
            rightStatisticsData: [], //
        };
    },

    mounted() {
        
        this.getDate()
        this.getWeek()

        var that = this
        this.$axios.post("/p/staff/tree").then(res => {
            this.departmentOptions = res.data.data.department;
            getChild(res.data.data.department[0])
            function getChild (data) {
                if(data.children) {
                    getChild(data.children[0])
                } else {
                    that.deparmentId = [data.uuid]
                    return
                }
            }
            setTimeout(() => {
                this.create()
            })
        })
    },

    methods: {
        create () {
            // 人员总览
            this.$axios.post('/p/cockpit/departmentStatistics', this.$qs({
                start_date: this.startTime,
                end_date: this.endTime,
                department_uuid: this.deparmentId.toString()
            })).then(res => {
                if(res.data.code == 0) {
                    this.personData = res.data.data
                }
            })

            // 各运动队在队人数统计
            this.$axios.post('/p/cockpit/departmentStaff', this.$qs({
                start_date: this.startTime,
                end_date: this.endTime,
                department_uuid: this.deparmentId.toString()
            })).then(res => {
                if(res.data.code == 0) {
                    // this. = res.data.data
                    this.departmentData = res.data.data
                }
            })

            // 在队运动员年龄统计
            this.$axios.post('/p/cockpit/ageStaff', this.$qs({
                start_date: this.startTime,
                end_date: this.endTime,
                department_uuid: this.deparmentId.toString()
            })).then(res => {
                if(res.data.code == 0) {
                    this.ageStaffData = res.data.data
                    this.ageSataEcharts(res.data.data)
                }
            })

            // 在队运动员性别统计
            this.$axios.post('/p/cockpit/sexStaff', this.$qs({
                start_date: this.startTime,
                end_date: this.endTime,
                department_uuid: this.deparmentId.toString()
            })).then(res => {
                if(res.data.code == 0) {
                    this.sexStaffData = res.data.data
                    this.sexSataType = res.data.data[2].num > res.data.data[1].num ? false : true
                }
            })

            // 今日数据记录人数
            this.$axios.post('/p/cockpit/recordStatistics', this.$qs({
                start_date: this.startTime,
                end_date: this.endTime,
                department_uuid: this.deparmentId.toString()
            })).then(res => {
                if(res.data.code == 0) {
                    this.recordStatisticsData = res.data.data
                }
            })

            // 近一年测试记录次数总览
            this.$axios.post('/p/cockpit/yearStatistics', this.$qs({
                start_date: this.startTime,
                end_date: this.endTime,
                department_uuid: this.deparmentId.toString()
            })).then(res => {
                if(res.data.code == 0) {
                    this.yearStatisticsData = res.data.data
                    this.yearEcharts(res.data.data)
                }
            })

            // 右侧接口
            this.$axios.post('/p/cockpit/rightStatistics', this.$qs({
                start_date: this.startTime,
                end_date: this.endTime,
                department_uuid: this.deparmentId.toString()
            })).then(res => {
                if(res.data.code == 0) {
                    this.rightStatisticsData = res.data.data
                    this.traSiteEcharts(res.data.data.train_position)
                    this.funJKEcharts(res.data.data.jn_norm)
                    this.injuryEcharts(res.data.data.injury_type, res.data.data.injury_total)
                    // this.injury_total = res.data.data.injury_total
                }
            })

            // this.ageSataEcharts()


            // this.traSiteEcharts()

            // this.funJKEcharts()

            // this.injuryEcharts()
        },

        ageSataEcharts (c_data) {
            let ageData = [], numData = []

            c_data.forEach(item => {
                if(item.age == 10) {
                    ageData.push('10岁及以下')
                } else if (item.age == 35) {
                    ageData.push('35岁及以上')
                } else {
                    ageData.push(item.age)
                }
                numData.push(item.num)
            })

            let serum = this.$echarts.init(
                document.getElementById("_ageSata_echarts")
            );
            let optionS = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    },
                    formatter: '年龄：{b}<br />人数：{c}人'
                },
                color: ["#1790FF", "#2ED1EC"],
                grid: {
                    left: '5%',
                    right: '4%',
                    bottom: '5%',
                    top: '28%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    offset: 1,
                    data: ageData,
                    axisLabel: {
                        color: '#2BC7E5'
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            // color: '#E1E1E1'
                        }
                    }
                },
                yAxis: {
                    show: true,
                    name: '单位：人',
                    type: "value",
                    minInterval: 5,
                    splitLine: {
                        //网格线
                        show: true,
                        lineStyle: {
                            color: '#0F3780',
                            type: 'dashed'
                        }
                    },
                    axisLine: { show: false, lineStyle: { color: "#2BC7E5" } },
                    axisTick: {
                        show: false
                    },
                    // nameTextStyle: {
                    //     padding: [0, 0, 10, 0]
                    // }
                },
                series: [
                    {
                        name: '人数：',
                        type: 'line',
                        barWidth: '10',
                        smooth: true,
                        symbolSize: 12,
                        lineStyle: {
                            width: 3
                        },
                        itemStyle: {
                            color: '#2ED1EC',
                            shadowColor: '#cfc',
                            borderWidth: 3
                        },
                        showBackground: true,
                        backgroundStyle: {
                            barBorderRadius: 10
                        },
                        label: {
                            show: false,
                            position: 'top'
                        },
                        itemStyle: {
                            normal:  {
                                barBorderRadius: 10
                            }
                        },
                        itemStyle: {
                            color: '#2ED1EC',
                            shadowColor: '#cfc'
                        },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#47F3FE'
                                    }, {
                                        offset: 1,
                                        color: '#80acd875'
                                    }
                                ],
                                global: false
                            }
                        },
                        data: numData
                    }
                ]
            };
            serum.setOption(optionS);
            this.$echartsResize(serum);
        },

        yearEcharts (c_data) {
            var tn_data = [], jn_data = [], c_date = []
            for(var a in c_data) {
                tn_data.push(c_data[a].tn_num)
                jn_data.push(c_data[a].jn_num)
                c_date.push(c_data[a].test_date_format)
            }
            let serum = this.$echarts.init(
                document.getElementById("_year_content")
            );
            let optionS = {
                title: {
                    x: 'center',
                    textStyle: {
                        //标题内容的样式
                        color: "#fff", //京东红
                        fontStyle: "normal", //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
                        fontWeight: "normal", //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
                        fontFamily: "MicrosoftYaHei", //主题文字字体，默认微软雅黑
                        fontSize: 16 //主题文字字体大小，默认为18px
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    },
                    // formatter: '{b}'
                },
                legend: {
                    data: ["机能监控记录数", "体能测试记录数"],
                    // right: 40,
                    top: 10,
                    left: "center",
                    // icon: "rect", //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
                    // itemWidth: 10, // 设置宽度
                    // itemHeight: 10, // 设置高度
                    itemGap: 20, // 设置间距
                    itemStyle: {
                    },
                    textStyle: {
                        color: '#fff'
                    }
                },
                // color: ["#1790FF", "#2ED1EC"],
                color: [{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color:  '#46ECFD'
                        },
                        {
                            offset: 1,
                            color:  '#181783'
                        }
                    ]
                }, "#1790FF"],
                grid: {
                    left: '4%',
                    right: '4%',
                    bottom: '5%',
                    top: '25%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    offset: 1,
                    data: c_date,
                    axisLabel: {
                        color: '#2BC7E5',
                        interval: 0
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            // color: '#E1E1E1'
                        }
                    }
                },
                yAxis: {
                    show: true,
                    name: '单位：个',
                    type: "value",
                    // minInterval: 3,
                    splitLine: {
                        //网格线
                        show: true,
                        lineStyle: {
                            color: '#0F3780',
                            type: 'dashed'
                        }
                    },
                    axisLine: { show: false, lineStyle: { color: "#2BC7E5" } },
                    axisTick: {
                        show: false
                    },
                    nameTextStyle: {
                        padding: [0, 0, 10, 0]
                    }
                },
                series: [
                    {
                        name: '机能监控记录数',
                        type: 'bar',
                        barWidth: '18',
                        label: {
                            show: false,
                            position: 'top'
                        },
                        itemStyle: {
                            normal:  {
                                color: this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: "#cfc" // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: "#fcc" // 60% 处的颜色
                                }], false)
                            }
                        },
                        // data: [900, 345, 393, '-', '-', 135, 178, 286, '-', '-', '-']
                        data: jn_data
                    },
                    {
                        name: '体能测试记录数',
                        type: 'line',
                        label: {
                            show: false,
                            position: 'top'
                        },
                        smooth: true,
                        symbolSize: 12,
                        lineStyle: {
                            width: 3
                        },
                        itemStyle: {
                            color: '#2ED1EC',
                            shadowColor: '#cfc',
                            borderWidth: 3
                        },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#80acd8'  //#47F3FE
                                    }, {
                                        offset: 1,
                                        color: '#80acd835'
                                    }
                                ],
                                global: false
                            }
                        },
                        // data: [900, 345, 393, '-', '-', 135, 178, 286, '-', '-', '-']
                        data: tn_data
                    },
                ]
            };
            serum.setOption(optionS);
            this.$echartsResize(serum);
        },

        traSiteEcharts (c_data) {
            var x_data = [], y_data = []
            c_data.forEach(item => {
                x_data.push(item.name)
                y_data.push(item.num)
            })
            let serum = this.$echarts.init(
                document.getElementById("_traSite_echarts")
            );
            let optionS = {
                // title: {
                //     x: 'center',
                //     textStyle: {
                //         //标题内容的样式
                //         color: "#fff", //京东红
                //         fontStyle: "normal", //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
                //         fontWeight: "normal", //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
                //         fontFamily: "MicrosoftYaHei", //主题文字字体，默认微软雅黑
                //         fontSize: 16 //主题文字字体大小，默认为18px
                //     }
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                // legend: {
                //     data: ["机能监控记录数", "体能测试记录数"],
                //     // right: 40,
                //     top: 10,
                //     left: "center",
                //     icon: "rect", //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
                //     itemWidth: 10, // 设置宽度
                //     itemHeight: 10, // 设置高度
                //     itemGap: 20, // 设置间距
                //     itemStyle: {
                //     },
                //     textStyle: {
                //         color: '#fff'
                //     }
                // },
                color: [{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color:  '#2EE1FF'
                        },
                        {
                            offset: 1,
                            color:  '#052AA7'
                        }
                    ]
                }],
                grid: {
                    left: '5%',
                    right: '4%',
                    bottom: '5%',
                    top: '28%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    offset: 1,
                    minInterval: 0,
                    data: x_data,
                    axisLabel: {
                        color: '#2BC7E5',
                        interval: 0,
                        formatter: function (name) {
                            if(name.length > 2) {
                                return name.slice(0, 4) + '...'
                            }  else {
                                return name
                            }
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            // color: '#E1E1E1'
                        }
                    }
                },
                yAxis: {
                    show: true,
                    name: '单位：次',
                    type: "value",
                    minInterval: 10,
                    splitLine: {
                        //网格线
                        show: true,
                        lineStyle: {
                            color: '#0F3780',
                            type: 'dashed'
                        }
                    },
                    axisLine: { show: false, lineStyle: { color: "#2BC7E5" } },
                    axisTick: {
                        show: false
                    },
                    // nameTextStyle: {
                    //     padding: [0, 0, 10, 0]
                    // }
                },
                series: [
                    {
                        name: '频次：',
                        type: 'bar',
                        barWidth: '10',
                        showBackground: true,
                        backgroundStyle: {
                            barBorderRadius: 10
                        },
                        label: {
                            show: false,
                            position: 'top'
                        },
                        itemStyle: {
                            normal:  {
                                barBorderRadius: 10
                            }
                        },
                        // data: [900, 345, 393, '-', '-', 135, 178, 286, '-', '-', '-']
                        data: y_data
                    }
                ]
            };
            serum.setOption(optionS);
            this.$echartsResize(serum);
        },
        
        funJKEcharts (c_data) {
            var x_data = [], y_data = []
            c_data.forEach(item => {
                x_data.push(item.cn_name)
                y_data.push(item.num)
            })
            let serum = this.$echarts.init(
                document.getElementById("_funJK_echarts")
            );
            let optionS = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                color: [{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color:  '#2EE1FF'
                        },
                        {
                            offset: 1,
                            color:  '#052AA7'
                        }
                    ]
                }],
                grid: {
                    left: '5%',
                    right: '4%',
                    bottom: '5%',
                    top: '28%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    offset: 1,
                    // formatter: function (name) {
                    //     if(name.length > 4) {
                    //         return name.slice(0, 4) + '...'
                    //     }  else {
                    //         return name
                    //     }
                    // },
                    nameTextStyle: {
                        normal: {
                            width: '5px',
                            overflow: 'truncate',
                            ellipsis: '...'
                        }
                    },
                    data: x_data,
                    axisLabel: {
                        color: '#2BC7E5',
                        interval: 0,
                        width: '4px',
                        overflow: 'truncate',
                        ellipsis: '...',
                        formatter: function (name) {
                            if(name.length > 2) {
                                return name.slice(0, 4) + '...'
                            }  else {
                                return name
                            }
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            // color: '#E1E1E1'
                        }
                    }
                },
                yAxis: {
                    show: true,
                    name: '单位：次',
                    type: "value",
                    minInterval: 10,
                    splitLine: {
                        //网格线
                        show: true,
                        lineStyle: {
                            color: '#0F3780',
                            type: 'dashed'
                        }
                    },
                    axisLine: { show: false, lineStyle: { color: "#2BC7E5" } },
                    axisTick: {
                        show: false
                    },
                    // nameTextStyle: {
                    //     padding: [0, 0, 10, 0]
                    // }
                },
                series: [
                    {
                        name: '频次：',
                        type: 'bar',
                        barWidth: '10',
                        showBackground: true,
                        backgroundStyle: {
                            barBorderRadius: 10
                        },
                        label: {
                            show: false,
                            position: 'top'
                        },
                        itemStyle: {
                            normal:  {
                                barBorderRadius: 10
                            }
                        },
                        // data: [900, 345, 393, '-', '-', 135, 178, 286, '-', '-', '-']
                        data: y_data
                    }
                ]
            };
            serum.setOption(optionS);
            this.$echartsResize(serum);
        },

        injuryEcharts (c_data, c_number) {
            var number = c_number
            var x_data = [], y_data = [], o_data = {}

            c_data.forEach((item, index) => {
                o_data[item.name] = item.num
                x_data.push(item.name)
                y_data.push({value: item.num, name: item.name })
            })
            let serum = this.$echarts.init(
                document.getElementById("_Injury_echarts")
            );
            let optionS = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    right: '1%',
                    icon: "circle",
                    type: 'scroll',
                    orient: 'vertical',
                    data: x_data,
                    pageIconColor: '#147DF7',
                    pageIconInactiveColor: '#147DF7',
                    pageTextStyle: {
                        color: '#fff'
                    },
                    formatter: function (name) {
                        if (!name) return;
                        return (
                            "{a|" +
                            name + "}{b|" + o_data[name] + "}")
                    },
                    textStyle: {
                        color: '#fff',
                        rich: {
                            a: {
                                fontFamily: "MicrosoftYaHei",
                                color: "rgba(255, 255, 255, 1)",
                                width: 80
                            },
                            b: {
                                fontFamily: "MicrosoftYaHei",
                                color: "rgba(255, 255, 255, 1)",
                                textAlign: "right",
                                width: 20
                            },
                            c: {
                                fontFamily: "MicrosoftYaHei",
                                color: "rgba(51,51,51,1)",
                                textAlign: "right",
                            }
                        }
                    },
                },
                color: ['#B53FFF', '#FACD48', '#31D1FF', '#FF638F', '#839deb', '#1A6FFF'],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['75%', '85%'],
                        center: ['30%', '50%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        // emphasis: {
                        //     label: {
                        //     show: true,
                        //     fontSize: '40',
                        //     fontWeight: 'bold'
                        //     }
                        // },
                        // injury_total：
                        labelLine: {
                            show: false
                        },
                        label: {
                            normal: {
                                show: true,
                                position: 'center',
                                formatter: '{total|' + number +'}'+ '\n\r' + '{active|伤病总人数}',
                                rich: {
                                    total: {
                                        fontSize: 23,
                                        color: '#FFD95B'
                                    },
                                    active: {
                                        fontSize: 10,
                                        lineHeight: 25,
                                        color: '#FFD95B'
                                    }
                                }
                            }
                        },
                        data: y_data
                    },
                    {
                        name: '外边框',
                        type: 'pie',
                        clockWise: false, //顺时加载
                        hoverAnimation: false, //鼠标移入变大
                        radius: ['68%', '69%'],
                        center: ['30%', '50%'],
                        label: {
                            normal: {
                                show: false
                            }
                        },
                        tooltip: {
                            show: false
                        },
                        data: [{
                            value: 9,
                            name: '',
                            itemStyle: {
                                normal: {
                                    borderWidth: 2,
                                    borderColor: '#00E0DB'
                                }
                            }
                        }]
                    },
                    {
                        name: '',
                        type: 'pie',
                        clockWise: false, //顺时加载
                        hoverAnimation: false, //鼠标移入变大
                        radius: ['60%', '62%'],
                        center: ['30%', '50%'],
                        label: {
                            normal: {
                                show: false
                            }
                        },
                        tooltip: {
                            show: false
                        },
                        itemStyle: {
                            borderWidth: 0
                        },  
                        color: ['rgba(255, 255, 255, 0)'],
                        // data: []
                        data: [{
                            value: 1,
                            name: '外边框',
                            itemStyle: {
                                normal: {
                                    borderWidth: 1,
                                    borderType: 'dotted',
                                    borderColor: '##00E0D'
                                }
                            }
                        }]
                    },
                    {
                        name: '',
                        type: 'pie',
                        clockWise: false, //顺时加载
                        hoverAnimation: false, //鼠标移入变大
                        radius: ['53%', '55%'],
                        center: ['30%', '50%'],
                        label: {
                            normal: {
                                show: false
                            }
                        },
                        tooltip: {
                            show: false
                        },
                        labelLine: {
                            show: false,
                            width: 0,
                            lineStyle: {
                                // type: 'dashed'
                            }
                        },
                        itemStyle: {
                            borderWidth: 0
                        },  
                        color: ['rgba(255, 255, 255, 0)'],
                        // data: []
                        data: [{
                            value: 1,
                            name: '外边框1',
                            itemStyle: {
                                normal: {
                                    borderWidth:1,
                                    borderType: 'dotted',
                                    borderColor: '#005887'
                                }
                            }
                        }]
                    },
                ]
            };

            serum.setOption(optionS);
            this.$echartsResize(serum);
        },

        progressFormat (value) {
            return value ? value : '0'
        },

        getDate () {
            var nowDate = new Date();
            var year = nowDate.getFullYear();
            var month = nowDate.getMonth() + 1;
            var strDate = nowDate.getDate();
            var days = nowDate.getDay()
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            this.currentdate = year + '年' + month + '月' + strDate + '日';

            switch (days) {
                case 1:
                    this.currentweek = '星期一'
                    break
                case 2:
                    this.currentweek = '星期二'
                    break
                case 3:
                    this.currentweek = '星期三'
                    break
                case 4:
                    this.currentweek = '星期四'
                    break
                case 5:
                    this.currentweek = '星期五'
                    break
                case 6:
                    this.currentweek = '星期六'
                    break
                case 0:
                    this.currentweek = '星期日'
                    break
            }
        },

        getData () {
            this.create()
        },

        weekClick (value) {
            if (value == 'week') {
                this.weekType = true
                this.getWeek()
            } else {
                this.weekType = false
                this.getMonth()
            }
            this.create()
        },

        getWeek () {
            this.startTime = this.setDate(new Date(new Date().getTime()-(3600*1000*24*(new Date().getDay()-1))))
            this.endTime = this.setDate(new Date(new Date().getTime()+(3600*1000*24*(7-new Date().getDay()))))
        },

        getMonth () {
            var date = new Date()
            this.startTime = this.setDate(new Date(date.setDate(1)))
            this.endTime= this.setDate(new Date(date.getFullYear(), date.getMonth() + 1, 0))
        },

        setDate(nowDate) {
            var year = nowDate.getFullYear();
            var month = nowDate.getMonth() + 1;
            var strDate = nowDate.getDate();

            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            return year + '-' + month + '-' + strDate;
        }
    },
};
</script>

<style lang="scss" scoped>
._cockpit{
    // height: 100%;
    height: 100vh;
    overflow: hidden;
    background-image: url('../../static/beijing.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;

    ._cockpit_tit{
        width: 100%;
        height: 10.5vh;
        // line-height: vh;
        background-image: url('../../static/title.png');
        background-size: 100% 80%;
        background-repeat-y: no-repeat;
        display: flex;
        justify-content: space-between;
        ._cockpit_tit_left {
            width: 30%;
            display: flex;
            align-items: flex-end;
            // padding-bottom: 10px;
            padding-left: 20px;
            span{
                display: block;
                background-image: url('./../../static/dateBorder.png');
                background-size: 100% 100%;
                padding: 7px 15px;
                color: #3AEAF7;
                margin-right: 15px;
            }
        }
        ._cockpit_tit_right {
            width: 30%;
            text-align: right;
            padding-right: 20px;
            position: relative;
            p{
                margin-top: 4vh;
                background: linear-gradient(180deg, #61F7FE 0%, #147DF7 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            /deep/ .el-cascader {
                // margin-right: 110px;
                margin-right: 3.5vw;
                .el-input__inner {
                    width: 190px;
                    height: 35px !important;
                    line-height: 30px;
                    background: #93949900;
                    border-image: linear-gradient(133deg, rgba(84, 199, 234, 1), rgba(52, 98, 191, 1)) 2 2;
                    border-radius: 10px;
                }
                .el-tag {
                    color: #fff;
                    background: #0731AB;
                    // background: #839deb;
                    margin-top: 5px;
                }
            }
            ._cockpit_select {
                position: absolute;
                bottom: 0px;
                right: 20px;
            }
            .title_btn {
                display: inline-block;
                padding: 3px 8px;
                color: #fff;
                border: 1px solid #ffffff00;
                margin-top: 5px;
                margin-left: 20px;
                &:hover{
                    cursor: pointer;
                }
            }
            .titleActive {
                border-image: linear-gradient(133deg, rgba(84, 199, 234, 1), rgba(52, 98, 191, 1)) 2 2;
                background: linear-gradient(180deg, #61F7FE 0%, #147DF7 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .c_title{
            width: 450px;
            font-size: 40px;
            font-weight: 600;
            text-align: center;
            line-height: 8vh;
            color: #FFFFFF;
        }
    }
    ._cockpit_box{
        display: flex;
        // flex: 1;
        padding: 0 20px;
        // height: 800px;
        overflow-y: auto;
        margin-top: 1vh;
        overflow-x: hidden;
        // margin-top: 20px;

        ._modularTit{
            // width: 20vh;
            // height: 5vh;
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            text-align: center;
            margin: 0 auto;
            padding-top: 25px;
            color: transparent;
            background: linear-gradient(90deg, #0377EC 0%, #03E3F2 47%, #02B5FD 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        ._cockpit_lft{
            width: 23%;
            height: 100%;
            // background: aquamarine;

            ._personnel{
                height: 18vh;
                background-image: url('../../static/kuang.png');
                background-size: 100% 100%;
                margin-bottom: 15px;
                overflow: hidden;
                .person_ul {
                    display: flex;
                    padding: 0 10px;
                    margin-top: 1vh;
                    li{
                        flex: 1;
                        margin-right: 0.5vw;

                        span{
                            display: block;
                            width: 4vw;
                            height: 4vw;
                            background-size: 100% 100%;
                            text-align: center;
                            line-height: 4vw;
                            font-size: 20px;
                            margin: 0 auto;
                        }
                        p {
                            text-align: center;
                            margin-top: 1vh;
                        }
                        &:first-child {
                                color: #B967F3;
                            span {
                                background-image: url('./../../static/person_z.png');
                                color: #B967F3;
                            }
                        }
                        &:nth-child(2) {
                                color: #DA954B;
                            span {
                                background-image: url('./../../static/person_c.png');
                                color: #DA954B;
                            }
                        }
                        &:nth-child(3) {
                                color: #19ECFF;
                            span {
                                background-image: url('./../../static/person_l.png');
                                color: #19ECFF;
                            }
                        }
                        &:nth-child(4) {
                            margin-right: 0;
                                color: #67DB66;
                            span {
                                background-image: url('./../../static/person_g.png');
                                color: #67DB66;
                            }
                        }
                    }
                }
            }
            ._team{
                height: 28.3vh;
                background-image: url('../../static/kuang1.png');
                background-size: 100% 100%;
                margin-bottom: 15px;
                ._team_ul {
                    height: 21vh;
                    overflow-y: auto;
                    padding-left: 1vw;
                    margin-top: 1vh;
                    li {
                        display: flex;
                        align-items: center;
                        line-height: 30px;
                        ._team_ul_number {
                            width: 1.8vh;
                            height: 1.8vh;
                            font-style:normal;
                            border-radius: 50%;
                            line-height: 1.8vh;
                            text-align: center;
                            border: 1px solid #00EFE9;
                            color: #00EFE9;
                            font-size: 12px;
                        }
                        &:first-child {
                            ._team_ul_number {
                                color: #fff;
                                border-color: #FFB941;
                                background: linear-gradient(180deg, #FFB941 0%, #DB35A9 100%);
                            }
                        }
                        &:nth-child(2) {
                            ._team_ul_number {
                                color: #fff;
                                border-color: #F9D87B;
                                background: linear-gradient(180deg, #F9D87B 0%, #F28D32 100%);;
                            }
                        }
                        &:nth-child(3) {
                            ._team_ul_number {
                                color: #fff;
                                border-color: #72D4FF;
                                background: linear-gradient(180deg, #72D4FF 0%, #0764ED 100%);
                            }
                        }
                        ._team_ul_name {
                            color: #fff;
                            margin-left: 10px;
                            width: 5.7vw;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                        }
                        /deep/ .el-progress {
                            width: 75%;
                            margin: 3px 0px 3px 10px;
                            .el-progress-bar__outer {
                                height: 12px !important;
                                background: rgba($color: #112C7F, $alpha: 1)
                            }
                            .el-progress-bar {
                                // margin-top: 5px;
                            }
                            .el-progress-bar__inner {
                                background: linear-gradient(90deg, #052AA7 0%, #2EE1FF 100%);;
                            }
                            .el-progress__text {
                                color: #39E8F6;
                            }
                        }
                    }
                }
            }
            ._ageSata{
                height: 18vh;
                background-image: url('../../static/kuang.png');
                background-size: 100% 100%;
                margin-bottom: 15px;
                ._ageSata_echarts {
                    width: 100%;
                    height: 12vh;
                }
            }
            ._sexSata{
                height: 18vh;
                background-image: url('../../static/kuang.png');
                background-size: 100% 100%;
                ._sexSata_img {
                    display: flex;
                    padding: 0 20px;
                    box-sizing: border-box;
                    margin-top: 2vh;
                    li {
                        flex: 1;
                        margin-right: 5px;
                        &:last-child{
                            margin-right: 0;
                        }
                        img {
                            width: 100%;
                        }
                    }
                }
                ._sexSata_number {
                    padding: 0 20px;
                    box-sizing: border-box;
                    span {
                        display: inline-block;
                        text-align: center;
                        width: 50%;
                        font-size: 17px;
                        &:first-child {
                            color: #61D9ED;
                        }
                        &:last-child {
                            color: #DD75EF;
                        }
                    }
                }
            }
        }

        ._cockpit_cont{
            width: 54%;
            margin: 0 15px;
            // background: bisque;

            ._today{
                height: 48.3vh;
                background-image: url('./../../static/kuang4.png');
                background-size: 100% 100%;
                margin-bottom: 15px;
                position: relative;
                ._today_bot_back {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    ._today_back {
                        width: 100%;
                        height: 100%;
                        background-image: url('./../../static/todayBack.png');
                        background-size: 100% 100%;
                        // animation: moves 5s infinite linear; 
                    }
                    @keyframes moves {
                        0% {
                            transform: rotate(0);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                    // position: relative;
                    ._today_conImg {
                        width: 9vw;
                        height: 7vw;
                        // background: #cfc;
                        background-image: url('./../../static/today_cons.png');
                        background-size: 100% 100%;
                        position: absolute;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        &:first-child {
                            top: 55%;
                            left: 5%;
                            animation: jump1 0.6s ease-in-out infinite alternate;
                        }
                        @keyframes jump1 {
                            0% { top: 55%; }
                            100% { top: 58%; }
                        }

                        &:nth-child(2) {
                            top: 30%;
                            left: 21%;
                            animation: jump2 0.6s ease-in-out infinite alternate;
                        }
                        @keyframes jump2 {
                            0% { top: 30%; }
                            100% { top: 33%; }
                        }

                        &:nth-child(3) {
                            top: 18%;
                            left: 41%;
                            animation: jump3 0.6s ease-in-out infinite alternate;
                        }
                        @keyframes jump3 {
                            0% { top: 18%; }
                            100% { top: 21%; }
                        }

                        &:nth-child(4) {
                            top: 30%;
                            right: 21%;
                            animation: jump2 0.6s ease-in-out infinite alternate;
                        }

                        &:nth-child(5) {
                            top: 55%;
                            right: 5%;
                            animation: jump1 0.6s ease-in-out infinite alternate;
                        }
                        
                        span{
                            display: block;
                            color: #36CDE6;
                            b {
                                font-size: 3.5vh;
                            }
                        }
                        p{
                            padding: 3px 10px;
                            color: #fff;
                            background: rgba($color: #2C4E9B, $alpha: 0.7);
                            position: absolute;
                            bottom: 0px;
                            // left: 50%;
                            // transform: translateX(-50%);
                        }
                    }
                }
                @keyframes move {
                    0% {
                        transform: rotateX(0deg);
                    }
                    100% {
                        transform: rotateX(180deg) rotateX(360deg);
                    }
                }
            }
            ._year{
                height: 37vh;
                background-image: url('../../static/kuang2.png');
                background-size: 100% 100%;
                ._year_content {
                    width: 100%;
                    height: 31.5vh;
                }
            }
        }

        ._cockpit_rig{
            width: 23%;
            // background: blueviolet;

            ._traSite{
                height: 18vh;
                background-image: url('../../static/kuang.png');
                background-size: 100% 100%;
                margin-bottom: 15px;
                ._traSite_echarts {
                    width: 100%;
                    height: 12.5vh;
                }
            }

            ._funJK{
                height: 18vh;
                background-image: url('../../static/kuang.png');
                background-size: 100% 100%;
                margin-bottom: 15px;
                ._funJK_echarts {
                    width: 100%;
                    height: 12.5vh;
                }
            }

            ._Injury{
                height: 48vh;
                background-image: url('../../static/kuang3.png');
                background-size: 100% 100%;
                ._Injury_progress {
                    width: 100%;
                    height: 20vh;
                    overflow: hidden;
                    h2 {
                        color: #3AEAF7;
                        font-size: 18px;
                        margin-top: 1vh;
                        padding-left: 1vw;
                    }

                    ._Injury_ul {
                        height: 15.7vh;
                        overflow-y: auto;
                        padding-left: 1vw;
                        li {
                            display: flex;
                            align-items: center;
                            line-height: 30px;
                            ._team_ul_name {
                                color: #fff;
                                width: 4vw;
                                overflow: hidden;
                                text-overflow:ellipsis;
                                white-space: nowrap;
                            }
                            /deep/ .el-progress {
                                width: 85%;
                                margin: 3px 0px 3px 10px;
                                .el-progress-bar__outer {
                                    height: 12px !important;
                                    background: rgba($color: #112C7F, $alpha: 1)
                                }
                                .el-progress-bar {
                                    // margin-top: 5px;
                                }
                                .el-progress-bar__inner {
                                    background: linear-gradient(90deg, #052AA7 0%, #2EE1FF 100%);;
                                }
                                .el-progress__text {
                                    color: #39E8F6;
                                }
                            }
                        }
                    }
                }
                ._Injury_echarts_content {
                    width: 100%;
                    height: 22vh;
                    h2 {
                        color: #3AEAF7;
                        font-size: 18px;
                        padding-left: 1vw;
                    }
                    ._Injury_echarts {
                        width: 100%;
                        height: 19vh;
                    }
                }
            }
        }
    }


}

</style>
